/*jslint browser: true, nomen: true */
/*global $ */
$.fn.stickyFooter = function () {
	var
	$wrap = $(this),
	$window = $(window),
	$footer = $('#footer'),
	$body = $('body'),
	$footerPad = $wrap.find('#footer-pad');

	// adjust margin & padding bottom for element
	function adjustMarginPadding() {
		var adjustment = $footer.outerHeight();

		$wrap.css({
			'margin-bottom': -adjustment
		});

		$footerPad.css({
			'padding-bottom': adjustment,
		});
	}

	return this.each( function() {
		$window.on('resize', $.proxy(adjustMarginPadding, this));
		$.proxy( adjustMarginPadding, this )();
	});
};

$.fn.exists = function () {
  return $(this).length > 0;
};

function FormSubmitting () {

  this.init = function() {
    $(document).on('submit','form', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });

  }

  this.submit = function(form) {
	var submitText = $(form).find('[data-submit-text]');
	var submittingText = $(form).find('[data-submitting-text]');
	var btn = submitText.closest('button');
	submittingText.removeClass('hide');
	submitText.hide();
	btn.prop('disabled', true);

  }

  this.reset = function(form) {
    var submitText = $(form).find('[data-submit-text]');
    var submittingText = $(form).find('[data-submitting-text]');
    var btn = submitText.closest('button');
    submittingText.addClass('hide');
    submitText.show();
    btn.prop('disabled', false);
  }
}

function ExternalLinkHandler() {
    var hostname = document.location.hostname;

    this.matchInternalLink = [new RegExp("^http:\/\/(.*?)" + hostname)];

    this.addTargetAttribute = function (context) {

        var links = $(context).find('a').filter('[href^="http://"],[href^="https://"]');

        $.each(links, function () {
            var anchor = $(this);
            var href = anchor.attr('href');

            if (!(href.indexOf(document.domain) > -1 || href.indexOf(':') === -1)) {
                anchor.attr('target', '_blank').addClass('external_link');
            }
        });
    };
}

function thermometer() {

	$('.barometer').each(function () {
		var barometer = $(this);
		var mercury = $(this).find('.mercury');

		var target_in = mercury.attr('data-target');
		var progress_in = mercury.attr('data-current');

		var target = parseInt(target_in.replace(',', ''));
		var progress = parseInt(progress_in.replace(',', ''));

		
		percentageAmount =  Math.min( Math.round(progress / target * 1000) / 10, 100);

	    mercury.css({
	    	"height": percentageAmount + "%"
	    });
	});

}
	
function updateFieldName(element, add) {
	$(element).removeAttr('name');
	if (add) {
		$(element).attr('name', $(element).data('name'));
	}
}

// Maps Handling
function mapInitialize() {

	var canvas = 'map-canvas';

	if (!$('#' + canvas).exists()) {
		return;
	}

	var zoom = 17;
	if($('#' + canvas).data('zoomlevel')) {
		zoom = $('#' + canvas).data('zoomlevel');
	}

	var lat_array = [];
	var long_array = [];
	var content = [ ];
	var icons = [ ];
	var titles = [];

	$('#map-canvas div').each(function(i, e) {

		lat_array.push(parseFloat($(this).data('lat')));
		long_array.push(parseFloat($(this).data('lng')));
		content.push($(this).data('infobox'));
		titles.push($(this).data('title'));

		if($(this).data('icon')) {
			icons.push($(this).data('icon'));
		} else {
			icons.push('');
		}
	});

	var center_lat = lat_array[0];
	var center_lng = long_array[0];
	var map_style = google.maps.MapTypeId.TERRAIN;
	var allowdrag = true;
	var allowscroll = false;

	if($('#map-canvas').data('lat')) {
		center_lat = $('#map-canvas').data('lat');
	}

	if($('#map-canvas').data('lng')) {
		center_lng = $('#map-canvas').data('lng');
	}

	if($('#map-canvas').data('mapstyle')) {
		map_style = $('#map-canvas').data('mapstyle');
	}

	if($('#map-canvas').data('allowdraggable')) {
		allowdrag = $('#map-canvas').data('allowdraggable');
	}

	if($('#map-canvas').data('allowscroll')) {
		allowscroll = $('#map-canvas').data('allowscroll');
	}

	//      map_style = "google.maps.MapTypeId." + map_style;
	var mapOptions = {
		zoom: zoom,
		center: new google.maps.LatLng(center_lat,center_lng),
		mapTypeId: map_style,
		scrollwheel: allowscroll,
		draggable : allowdrag
	};

	var map = new google.maps.Map(document.getElementById(canvas), mapOptions);

	map.set('styles', [
		{ featureType: 'road',	elementType: 'labels.text.stroke', stylers: [{ visibility: 'off' }, { weight: 1 }]}
	]);

	var count = 0;
	$.each(lat_array, function(i) 
	{

		var infowindow = new google.maps.InfoWindow({
			content: content[i]
		});
		var marker_pos = new google.maps.LatLng(lat_array[i], long_array[i]);

		var marker_positions = {
			'position' : marker_pos,
			'map' : map,
			'title' : titles[i]
		}

		if(icons[i]) {
			marker_positions.icon = icons[i];
		}

		var marker = new google.maps.Marker( marker_positions );
		google.maps.event.addListener(marker, 'click', function() {
		infowindow.open(map,marker);
		});

		count = count+1;
	});
}

	
(function(){
	var showMessage;
	showMessage = function (options) {
		if (typeof options !== 'object')
			options = {message: 'Parameter not an object', error: true};
		options.message = options.message || 'No message specified';
		options.error = options.error || false;

		// console.log(options.error ? 'Error: ' : '', options.message);
	};
	this.loadSection = function (options) {
		var defer = $.Deferred(),
			$div, msg;

		if (typeof options !== 'object') {
			msg = 'Expecting parameter object';
			showMessage({message: msg, error: true});
			return defer.reject({statusText: msg});
		}

		options.selector = options.selector || '';
		options.url = options.url || '';
		options.dynamic = options.dynamic || false;

		if (options.url === '') {
			msg = 'Missing URL';
			showMessage({message: msg, error: true});
			return defer.reject({statusText: msg});
		}

		$div = $(options.selector)
		if ($div.length > 0) {
			$.get(options.url, function () {
			}, 'html')
				.done(function (result) {
					$div.html(result);
					if (!options.dynamic) {
						defer.resolve();
					}
				})
				.fail(function (result) {
					msg = 'Could not load URL: ' + options.url;
					showMessage({message: msg, error: true});
					defer.reject(result);
				});
			if (options.dynamic)
				$div.off('complete,failure')
					.on('complete', function (event) {
						defer.resolve();
					})
					.on('failure', function (event, result) {
						msg = 'Dynamic content: ' + result.statusText;
						showMessage({message: msg, error: true});
						defer.reject(result);
					});
		}
		else {
			msg = 'Error in selector';
			showMessage({message: msg, error: true});
			defer.reject({statusText: msg});
		}

		return defer.promise();
	};


}());

$(function () {
	'use strict';
	
	(new FormSubmitting()).init();

	if($('.js_format_curr').length) {
		var orig_int = $('.js_format_curr').text();
		var new_float = orig_int.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

		$('.js_format_curr').text(orig_int / 100);
	}

	$('.lightbox').magnificPopup({
		type:'image'
	});

	$('#wrap').stickyFooter();


	$.when(
		loadSection({selector: '.facebook_thandulwazi', url: "/includes/facebook_thandulwazi", loader: '.preloader'}),
		loadSection({selector: '.facebook_stithians', url: "/includes/facebook_stithians", loader: '.preloader'}),
		loadSection({selector: '.facebook_association', url: '/includes/facebook_association', loader: '.preloader'})
	)
	.always(function()
	{
		$.fn.matchHeight._update();
		setTimeout(function(){
			(new ExternalLinkHandler).addTargetAttribute($('.js-ajax-content'));
			(new ExternalLinkHandler).addTargetAttribute($('.social'));
		}, 500);
	});


	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.slider-nav'
	});

	$('.slider-nav').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		centerMode: true,
		focusOnSelect: true
	});


	$('a[href$=".pdf"]').attr('target', '_blank');

	$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		$.fn.matchHeight._update()
		mapInitialize();
	});
	
	

	google.maps.event.addDomListener(window, 'load', mapInitialize);


	var socialShare = function () {
	    $("[data-share-default]").each(function () {
	      var shareHandler = new ShareHandler($(this));
	      shareHandler.appendTwitter();
	      shareHandler.appendFacebook();
	      shareHandler.appendGoogle();
	      //shareHandler.appendLinkedIn();
	      shareHandler.appendPinterest();
	    });
	};

	socialShare();


	var waitedForResponse = function defer(method) {
		if (grecaptcha.getResponse())
			method(grecaptcha.getResponse())
		else
			setTimeout(function () {
				defer(method)
			}, 50);
	};

	$('.donate-submit').on('click', function() {

		var form = $(this).closest('form').get(0);

		waitedForResponse(function(response){
			console.log('token', response)
			var action = "/?ACT=" + form.process_act.value;

			var fields = {
				reference: form.reference.value,
				project: form.project ? form.project.value : '',
				project_name: form['project_name'] ? form.project_name.value : '',
				donate_amount: form.donate_amount.value,
				subs: form['subscription'] ? form['subscription'].value : 0,
				certificate: form['certificate'] ? (form['certificate'].checked ? 1 : 0) : 0,
				optin: form['optin'] ? (form['optin'].checked ? 1 : 0) : 0,
				name: form.name.value,
				EMAIL: form.EMAIL.value,
				phone: form.phone.value,
				address: form.address.value,
				anonymous: form.anonymous.checked ? 1 : 0,
				callback_act: form.callback_act.value,
				'g-recaptcha-response': form['g-recaptcha-response'].value,
				csrf_token: form.csrf_token.value,
				XID: form.XID.value
			};

			$.ajax({
				type: 'POST',
				url: action,
				data: fields,
				dataType: 'json',
				async: true,	
				beforeSend: function name() {
					(new FormSubmitting()).submit(form);	
				},
				success: function(data) {

					$(form).find('.is-invalid').removeClass('is-invalid');
					if (data['errors']) {
						$.each(data.errors, function(i, error){
							console.log(i, error);
							if (form[i].parentElement.classList.contains('select')) {
								form[i].parentElement.classList.add('is-invalid');
							}
							form[i].classList.add('is-invalid');
						});
					} else {
						// update data
						form.AMOUNT.value = data['amount'];
						form.REFERENCE.value = data['reference'];
						form.CURRENCY.value = data['currency'];
						form.RETURN_URL.value = data['return_url'];
						form.TRANSACTION_DATE.value = data['transaction_date'];
						form.CHECKSUM.value = data['checksum'];
						form.PAYGATE_ID.value = data['paygate_id'];
						
						if(form['subscription']) {
							form.PAYGATE_ID.value = data['paygate_id'];
							form.SUBS_START_DATE.value = data['subs_start_date'];
							form.SUBS_END_DATE.value = data['subs_end_date'];
							form.SUBS_FREQUENCY.value = data['subs_frequency'];
							form.PROCESS_NOW.value = data['process_now'];
							form.PROCESS_NOW_AMOUNT.value = data['process_now_amount'];

							console.log('SUBS_START_DATE', form.SUBS_START_DATE.value)
							console.log('SUBS_END_DATE', form.SUBS_END_DATE.value)
							console.log('SUBS_FREQUENCY', form.SUBS_FREQUENCY.value)
							console.log('PROCESS_NOW', form.PROCESS_NOW.value)
							console.log('PROCESS_NOW_AMOUNT', form.PROCESS_NOW_AMOUNT.value)
						}

						console.log('AMOUNT', form.AMOUNT.value);
						console.log('REFERENCE', form.REFERENCE.value);
						console.log('CURRENCY', form.CURRENCY.value);
						console.log('RETURN_URL', form.RETURN_URL.value);
						console.log('TRANSACTION_DATE', form.TRANSACTION_DATE.value);
						console.log('CHECKSUM', form.CHECKSUM.value);
						console.log('PAYGATE_ID', form.PAYGATE_ID.value);

						// remove fields
						form.donate_amount.remove();
						form.address.remove();
						form.anonymous.remove();
						form.optin.remove();
						
						if(form['subscription']) {
							if(form.subscription.value == 0) {
								form.PAYGATE_ID.remove();
								form.SUBS_START_DATE.remove();
								form.SUBS_END_DATE.remove();
								form.SUBS_FREQUENCY.remove();
								form.PROCESS_NOW.remove();
								form.PROCESS_NOW_AMOUNT.remove();
							}

							for (var index = 0; index < form.subscription.length; index++) {
								var el = form.subscription.item(index);

								
								el.removeAttribute('name');
							}

						}

						if (form['project_name']) {
							form.project_name.remove()
						}

						if(form['g-recaptcha-response']) {
							form['g-recaptcha-response'].remove();
						}

						// submit form
						form.setAttribute('action', data.paygate_url);
						form.submit();
					}

					if(data['redirect']) {
						window.location = data['redirect']; return false;
					}

					(new FormSubmitting()).reset(form);
				}
			});
		})

		return false;
	});

	$('[data-project-collapse]').on('change', function() 
	{
		var val = this.value;
		var linkTo = this.options[this.selectedIndex].dataset.href;

		if (linkTo !== undefined) 
		{
			window.location.href = linkTo;
		}

		var sel = $('[data-project-name] > .collapse').removeClass('in').filter(function(){
			updateFieldName($(this).find('select'), false);
			return this.id === val;
		}).addClass('in');
		updateFieldName(sel.find('select'), true);
	});

	$('[data-unite-gallery]').each(function(){
		$(this).unitegallery({
			slider_scale_mode: "fit",	
			slider_scale_mode_media: "fit",
		}); 
	})

	thermometer();

	$(window).on('load', function() {
		if($('#landing-ad').length > 0) {
		var now, lastDatePopupShowed;
		now = new Date();

		if (localStorage.getItem('lastDatePopupShowed') !== null) {
			lastDatePopupShowed = new Date(parseInt(localStorage.getItem('lastDatePopupShowed')));
		}

		if (((now - lastDatePopupShowed) >= (15 * 86400000)) || !lastDatePopupShowed) {
			$.magnificPopup.open({
			items: { src: '#landing-ad' },
			type: 'inline'
			}, 0);

			localStorage.setItem('lastDatePopupShowed', now);
		}
	}
	});

	$(".carousel-title").html(function(){
		var text= $(this).text().trim().split(" ");
		var first = text.shift();
		return (text.length > 0 ? "<strong>"+ first + "</strong> " : first) + text.join(" ");
	});
});